@import "../../../../styles/Colors.scss";

.mobile-header {
  justify-content: space-between !important;
  padding: 0px 15px;
  position: fixed;
  top: 30px !important;
  width: 100%;
  z-index: 2;
  height: 65px;
  align-items: center;
  background-color: $surface;
  border-bottom: 1.5px solid var(--background-color);
  margin-bottom: 5px;
  .icons-section {
    .ant-space {
      gap: 10px !important;
    }
  }

  svg {
    font-size: 20px !important;
    color: $secondaryTwo;
  }
}

.hide-overflow {
  overflow: hidden;
}

html.hide-mobile-bottom-nav .mobile-bottom-nav {
  display: none;
}
html.hide-mobile-bottom-nav .product-buying-bottom-nav {
  display: none;
}

html.hide-mobile-bottom-nav .mobile-filters-bottom-nav {
  display: none;
}

// Mz Css
.menu-hamburger {
  // max-width: 0.1% !important;
  // top: -3px;
}
.hide-overflow {
  overflow: hidden;
}

body .display-flex {
  display: flex;
}

body .currency-dropdown-mobile-new-menu {
  display: flex;
}

body .currency-div-mobile-screen {
  width: 25px;
  width: max-content;
  font-size: 11px;
  font-weight: 600;
  align-self: center;
  position: relative;
  top: -2px;
}

body .mobile-header .ant-dropdown-menu.ant-dropdown-menu-root {
  margin-top: 3px;
}
.MobileSearch .searchBar {
  color: #000;
}
.mobile-header-account-container span {
  display: flex;
}
.mobile-header svg path{
  fill: var(--background-color)
}
.theme-type-default .menu-hamburger svg path{
  fill: rgb(214, 35, 20);
}