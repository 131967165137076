.AccountsSideNav {
}
.accountsSidebar {
  padding: 0px !important;
  .ant-drawer-wrapper-body {
    .ant-drawer-header {
      padding: 35px 30px 20px 40px !important;
      .ant-drawer-header-title {
        button {
          display: none !important;
        }
      }
    }
    .ant-drawer-body {
      padding: 0px !important;

      .selectedMenuItem {
        background: #e4e4e4 0% 0% no-repeat padding-box;
        opacity: 1;
      }
    }
  }
}
