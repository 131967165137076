@import "../../../../styles/Colors.scss";

.mobile-bottom-nav {
  position: fixed;
  padding: 0px 20px !important;
  bottom: 0px;
  width: 100%;
  z-index: 9;
  height: 73px;
  background: #f9f9f9;
  align-items: center;
  display: flex;
  justify-content: space-between;

  div {
    width: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 47px;

    .nav-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 3px;
      width: 100%;
      justify-content: center;

      span {
        color: black;
        font-size: 8px;
        height: 10px;
      }

      svg {
        color: $secondaryOne;
        font-size: 24px;
      }

      span.link-color {
        color: $primaryTwo;
      }
    }
  }

  div:nth-child(1) .nav-link {
    justify-content: flex-start;
  }

  div:nth-child(2) .nav-link {
    justify-content: flex-start;
  }

  div:nth-child(4) .nav-link {
    justify-content: flex-end;
  }

  div:nth-child(5) .nav-link {
    justify-content: flex-end;
  }

  div:nth-child(3) .nav-link {
    justify-content: center;
  }

  .nav-bottom-border {
    height: 3px;
    width: 100%;
  }

  .nav-bottom-border-color {
    background: $primaryTwo;
  }
}

.mobile-currency-radioGroup {
  .radio-item {
    width: 330px;
    margin-left: auto !important;
    margin-right: auto !important ;
  }
  .currency-name {
    font-size: 14px;
    font-weight: 500;
    color: #1e1e1e;
  }
  .ant-radio-wrapper {
    margin-left: 10px !important ;
  }
  .ant-radio-wrapper-checked {
    border: none !important;
  }
}

.mobile-currency-dropdown {
  width: 100%;
  padding: 0px !important;
  margin-top: 40px !important;

  // textAlign: "center",
  //padding: "10px 0px 0px 0px",
  .ant-dropdown-menu {
    border-radius: 30px 30px 0px 0px !important;

    .mobile-currency-header {
      border-bottom: 1px solid #f2f3f7;

      p {
        font-family: Lato;
        font-weight: 700;
      }
      svg {
        font-size: 22px;
        position: absolute;
        right: 10px;
      }
    }
    .mobile-currency-list {
    }
  }
}

// Mz Css
body.overlay-container:before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #000000a6;
  z-index: 2;
}
body.oveflow-hidden {
  overflow: hidden;
}
body .mobile-currency-dropdown {
  position: fixed;
  bottom: 47px !important;
}

//styles to import elsewhere

body .brands-heading-search-mobile {
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 15px;
}

body .horizontal-line-search-brands {
  height: 1px;
  width: 149px;
  background: #d9d9d9;
  margin-top: 5px;
  margin-bottom: 22px;
}

body .all-brand-list-main-heading {
  border-radius: 3px;
  border: 1px solid #000;
  text-align: center;
  width: 23px;
  height: 23px;
}

body .all-brand-list-main-heading-text {
  flex-shrink: 0;
  align-self: center;
  color: #000;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-top: 3px;
}

body .all-brand-list-brands-name {
  color: #6d6b6b;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3px;
}

body .trending-brands-search-mobile-single-div {
  align-self: center;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  display: flex;
  height: 100%;
  width: 100%;
  align-self: center;
  padding: 2px 5px;
  margin-right: 8px;
  justify-content: center;
}

body .brand-main-section-div {
  position: fixed;
  padding: 0px 21px 50px 21px;
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  z-index: 9;
  bottom: 0;
  padding-top: 5vh;
}

body .position-relative {
  position: relative;
}

body .position-absolute-search-image {
  position: absolute;
  right: 7px;
  top: 7px;
}

body .all-brand-list-main-div {
  margin-top: 45px;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 30vh;
}

.brand-main-section-div .slick-dots .slick-active button {
  background-color: #ed2590 !important;
}
.brand-main-section-div .trending-brands-search-mobile-single-div img {
  height: 30px;
  max-width: 100%;
}

body .trending-brands-search-mobile-single-div.sanasafina img {
  height: auto;
}

body .cross-button-brands-list {
  display: flex;
  justify-content: end;
  padding: 10px;
}

.brand-drawer .header {
  display: none;
}
.mobile-bottom-nav .cart-icon {
  position: relative;
}
body .mobile-bottom-nav .nav-link .cart-count-value {
  left: 22px;
  color: #fff;
  width: 14px;
  height: 14px;
  text-align: center;
}
body .nav-link.cart-icon svg path {
  fill: var(--background-color);
}
