@import "../../../../styles/Colors.scss";

.AccountsHeaderMobile {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: 48px;
  box-shadow: 0px 1px 1px #00000029;
  background-color: $surface;
  .icons-section {
    .ant-space {
      gap: 16px !important;
    }
  }

  svg {
    font-size: 20px !important;
    color: $secondaryTwo;
  }
}
