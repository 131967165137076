.whatsapp {
  display: flex !important;
}
.whatsapp-inner a {
  display: flex;
  color: #128c7e;
  width: max-content;
  position: fixed;
  background-color: var(--background-color) !important;
  right: 10px;
  bottom: 40px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  z-index: 2000;
}
.whatsapp-inner svg {
  width: 50px;
  height: 50px;
}
@media (max-width: 767px) {
  .whatsapp {
    display: none !important;
  }
  .whatsapp-inner a {
    bottom: 80px;
  }
}
